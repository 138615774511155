import React, { Component } from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Input, Button, Progress } from "antd";
// import "antd/dist/antd.css";
// import LocationAutocomplete from "../Components/Autocomplete";
// import { Radio } from "antd";
import { update } from "../../Api/Apis";
import AppConfig from ".././config/AppConfig";
// import { SearchOutlined } from "@ant-design/icons";
// import Search from "antd/lib/input/Search";
class Platform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],

      selectedRowKeys: [],
      thirdstep: "",
      selecteRedio: true,
      isdeselect: false,
    };
  }
  componentDidMount = () => {
    //  console.log(this.props,"ppp")
    this.setState({
      selected:
        this.props?.Platformdata && this.props.Platformdata.length > 0
          ? this.props.Platformdata
          : this.props.data2.platform && this.props.data2.platform.length > 0
          ? this.props.data2.platform
          : [],
    });
  };
  onSelectChange = (data) => {
    let selected = this.state.selected.length > 0 ? this.state.selected : [];
    if (selected.includes(data.target.value)) {
      let Index = selected.indexOf(data.target.value);
      selected.splice(Index, 1);
      this.setState({
        selected,
      });
    } else {
      selected.push(data.target.value);
      this.setState({
        selected,
      });
    }
  };
  handleClick = (e) => {
    // console.log(e.target.value, "ggggggg");
    // let selected = [];
    // this.state.selected?.splice(this.state.selected.indexOf(e.target.value), 1);
    // console.log(
    //   this.state?.selected,
    //   "selectedt",
    //   this.props?.marketdata,
    //   "markeData"
    // );
    let deleteSelected = this.state.selected?.filter(
      (i) => i === e.target.value
    );
    // console.log(deleteSelected, "hell", this.state.selected);
    if (deleteSelected && deleteSelected.length) {
      deleteSelected.splice(deleteSelected.indexOf(e.target.value), 1);
      this.setState({ selected: deleteSelected, isdeselect: true });
      // console.log(deleteSelected, "dddd");
    }
    // const target = data;

    // console.log(selected, "kkkkk");
    this.props.handleInputs(this.state.selected, "/app/Platform", "deselect");
    this.setState({ selecteRedio: true });
  };
  // handleClick = (e) => {
  //   console.log(this.state.selected);
  //   const platform = this.state.selected?.filter((i) => i !== e.target.value);
  //   // const target = data;
  //   this.setState({ platform, isdeselect: true });

  //   console.log(platform, "kkkkk");
  //   console.log(e.target.value);
  //   this.props.handleInputs(platform, "/app/Platform", "deselect");
  //   this.setState({ selecteRedio: true });
  // };
  handleRedioPlatform = (data) => {
    let checkValue = this.state.selected.includes(data);

    var selectedValue = checkValue.toString() == "true" ? true : false;

    return (
      // <Radio.Group buttonStyle="solid">
      //   <Radio.Button
      //     value={data}
      //     onChange={this.onSelectChange}
      //     onClick={this.handleClick}
      //     style={{
      //       borderRadius: "50px",
      //       textAlign: "center",
      //       marginLeft: "15px",
      //       backgroundColor: checkValue.toString() == 'true' ? "#1890ff" : "#ffff",
      //       color:checkValue.toString() == 'true' ? "#fff" : "#000",

      //     }}
      //     // checked={selectedValue}
      //   >
      //     {data}
      //   </Radio.Button>
      // </Radio.Group>
      <div>
        <label>
          <input
            name={data}
            type="checkbox"
            value={data}
            onChange={this.onSelectChange}
            onClick={this.handleClick}
            className="visually-hidden"
            checked={checkValue}
          />
          <div className="btn">{data}</div>
        </label>
      </div>
    );
  };
  // onSelectChange = (data) => {
  //   let selected = this.state.selected.length > 0 ? this.state.selected : [];
  //   selected.push(data.target.value);
  //   this.setState({
  //     selected,
  //   });

  //   console.log(selected);
  //   return;
  //   // let selected = this.state.selected;
  //   if (data.length > 0) {
  //     // selected.splice(selected.indexOf(data), 0);
  //     selected.push(data);
  //   } else {
  //     selected.push(data);
  //   }
  //   this.setState({ selectedKeys: data });
  //   // console.log(this.state.selected)
  // };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.selected.length === 0) {
      alert("Please select your options!");
      // console.log(this.state.selected);
      return;
    }
    this.setState({
      loading: true,
    });
    // console.log(this.state.selected);
    this.saveRegistrationData(this.state.selected);
  };
  saveRegistrationData = async (selected) => {
    this.props.handleInputs(this.state.selected, "/app/Industry");
    let data = {
      platform: selected,
    };
    let id =
      JSON.parse(localStorage.getItem("id")) ||
      JSON.parse(localStorage.getItem("userid"));
    // console.log(id);
    const Data = {
      entityData: data,
    };
    update(AppConfig["entityNames"]["registrations"], id, Data).then(
      (result) => {
        if (result.success) {
          // console.log(result.data.data.id);
          localStorage.setItem("user", JSON.stringify(result.data.data.id));
          this.props.history.push("/app/Industry");
        }
        if (result.err) {
          alert("your registration failed ");
          this.setState({ loading: false });
        }
      }
    );
  };
  searchData = (event, searchType) => {
    let searcingValue = searchType === "text" ? event.target.value : event;
    // console.log(event);
    let data = [];
    if (searcingValue.length > 0) {
      searcingValue = searcingValue.toLowerCase();
      data = this.value.filter(this.value);
    } else {
      data = this.value;
      this.setState({
        defaultStatus: "",
      });
    }
    this.setState({
      loadHistory: data,
    });
  };
  render() {
    const { selectedKeys, selecteRedio, isdeselect } = this.state;
    const Search = Input.Search;
    const Selection = {
      selectedKeys,
      onChange: this.onSelectChange,
    };
    // console.log(this.props.internet,"bwqsyuhbeuwy");
    // console.log(this.state.selected);
    const { Platformdata } = this.props;

    const Platform = [
      "Billboards",
      "Commuter Rail",
      "Radio",
      "Social Media",
      "Digital Bulletins",
      "Digital Posters",
      "Linear TV",
      "Direct Mail",
      "Newspaper",
      "Magazines",
      "Print",
      "Flyers",
      "Email Marketing",
      "Lead Management",
      "Event Management",
      "Webforms",
      "Surveys",
      "Restaurant Placemats",
      "Websites",
    ];
    // console.log(this.props.internetData,"platform111111111111111111111111");

    return (
      <Row>
        <div className="progress-mobile">
          <Progress
            percent={this.props.count * 10}
            size="small"
            status="active"
          />
        </div>
        <Col span={24}>
          <div style={{ textAlign: "center", marginTop: "100px" }}>
            <h1 style={{ fontWeight: "bold" }}>
              What advertising/marketing platforms do you offer services in?
            </h1>
          </div>
        </Col>
        <Col span={24}>
          <Form.Item className="Formitem">
            <p style={{ color: "gray", textAlign: "center" }}>
              select 1 or more
            </p>
          </Form.Item>
        </Col>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          {/* <Col span={12}>
            <Form.Item className="Formitem">
              <Input
                className=".ant-input-affix-wrapper .ant-input"
                prefix={<SearchOutlined />}
                onChange={this.searchData}
                style={{
                  marginLeft: "79%",
                }}
                editMode={false}
              />
            </Form.Item>
          </Col> */}
          {/* <Form.Item className="Formitem">
              {Industry.map((data) => (
                <div style={{ width: "max-content" , float:"left" ,marginLeft:"10px"}}>
                  {this.handleRedioIndustry(data)}
                </div>
              ))}
            </Form.Item> */}
          <Col lg={12} md={12} sm={24} xs={24} className="service-area">
            <Form.Item className="Formitem">
              {Platform.map((data) => (
                <div
                  style={{
                    width: "max-content",
                    float: "left",
                    marginLeft: "10px",
                  }}
                >
                  {this.handleRedioPlatform(data)}
                </div>
              ))}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item className="Formitem">
              {/* <Link to="/app/Platform"> */}
              <Button
                key="1"
                type="primary"
                loading={this.state.loading}
                // onClick={ this. window.history.back()}
                onClick={() => {
                  this.props?.internetData
                    ? this.props.handlePrevious("/app/Internetmarketing")
                    : this.props.handlePrevious("/app/Marketing");
                }}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  float: "right",
                  marginRight: "10px",
                  fontWeight: "bold",
                  height: "40px",
                  marginBottom: "80px",
                }}
              >
                Previous
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              {/* <Link to="/app/Industry"> */}
              <Button
                key="1"
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Next
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Platform;
