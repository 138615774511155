import React from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Progress } from "antd";
// import "antd/dist/antd.css";
// import { Radio } from "antd";
import { update } from "../../Api/Apis";
import AppConfig from ".././config/AppConfig";
// import { Link } from "react-router-dom";
class Marketing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedInternet: "",
      selected: [],
      selectedRowKeys: [],
      secondstep: "",
      checked: false,
      selecteRedio: true,
      isdeselect: false,

      marketing: [
        "Public relations",
        "Brand management",
        "Print marketing",
        "Event marketing",
        "Outdoor",
        "Broadcast marketing on television",
        "Broadcast marketing on radio",
        "Media buys",
        "Content creation",
        "Video production",
        "Audio production",
      ],
    };
  }
  componentDidMount = () => {
    // console.log(this.props, "this.props.marketdata");
    if (this.props.data2.marketing === "Internet marketing" || this.props.marketdata==="Internet marketing"||this.props.internetData==="Internet marketing") {
      this.setState({
        selectedInternet: "Internet marketing",
      });
    } else {
      this.setState({
        selected:
          this.props.marketdata && this.props.marketdata.length > 0
            ? this.props.marketdata
            : this.props.data2.marketing &&
              this.props.data2.marketing.length > 0 &&
              typeof this.props.data2.marketing !== "string"
            ? this.props.data2.marketing
            : [],
      });
    }
    // if(this.state.selected === "Internet marketing"){

    // }
  };
  // this.setState({
  //   selected: this.props.marketdata && this.props.marketdata.length > 0 ? this.props.marketdata : data.marketing && data.marketing.length > 0 ? data.marketing :[]
  // });
  handleClick = (e) => {
    // console.log(this.props.marketdata,"this.props.marketdata",this.props.data2.marketing);
    // console.log(e.target.value, "ggggggg",);
    // let selected = [];
    // this.state.selected?.splice(this.state.selected.indexOf(e.target.value), 1);
    // console.log(
    //   this.state?.selected,
    //   "selectedt",
    //   this.props?.marketdata,
    //   "markeData"
    // );
    let deleteSelected = this.state.selected?.filter(
      (i) => i === e.tselectedInternetarget.value
    );
    // console.log(deleteSelected, "hell", this.state.selected);
    if (deleteSelected && deleteSelected.length) {
      deleteSelected.splice(deleteSelected.indexOf(e.target.value), 1);
      this.setState({ selected: deleteSelected, isdeselect: true });
      // console.log(deleteSelected, "dddd");
    }
    // const target = data;

    // console.log(selected, "kkkkk");
    if (
      e.target.value === "Internet marketing" &&
      !this.state.selected.includes(e.target.value)
    ) {
      this.props.handleInputs(
        this.state.selected,
        "/app/Internetmarketing",
        "deselect"
      );
      this.setState({ selecteRedio: true });
    }
  };

  onSelectChange = (data) => {
    let selected = this.state.selected.length > 0 ? this.state.selected : [];
    if (this.state.selectedInternet === "Internet marketing") {
      selected = [];
      this.setState({
        selectedInternet: "",
      });
    }
    if (selected.includes(data.target.value)) {
      let Index = selected.indexOf(data.target.value);
      selected.splice(Index, 1);
      this.setState({
        selected,
      });
    } else {
      selected.push(data.target.value);
      this.setState({
        selected,
      });
    }
  };

  handleInternetMarketing = (e) => {
    this.setState({
      selectedInternet: e,
      selected: "",
    });
    // this.props.handleInputs(e, "/app/Internetmarketing");
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // console.log(this.state.selectedInternet, "selectedInternet");
    if (
      this.state.selected.length === 0 &&
      this.state.selectedInternet === ""
    ) {
      alert("Please select your options!");
      // console.log(this.state.secondstep);
      return;
    }

    this.setState({
      loading: true,
    });

    // console.log(this.state.selected);
    if (this.state.selectedInternet !== "") {
      this.saveRegistrationData(
        this.state.selectedInternet,
        "/app/Internetmarketing"
      );
    } else {
      this.saveRegistrationData(this.state.selected, "/app/Platform");
    }
  };

  saveRegistrationData = async (selected, path) => {
    this.props.handleInputs(selected, path);
    let data = {
      marketing: selected,
    };

    let id =
      JSON.parse(localStorage.getItem("id")) ||
      JSON.parse(localStorage.getItem("userid"));
    // console.log(id);
    const Data = {
      entityData: data,
    };
    update(AppConfig["entityNames"]["registrations"], id, Data).then(
      (result) => {
        if (result.success) {
          console.log(result.data,"rsult market");
          localStorage.setItem("user", JSON.stringify(result.data.data.id));
          // this.props.history.push("/app/Platform");
        }
        if (result.err) {
          alert("your registration failed ");
          this.setState({ loading: false });
        }
      }
    );
  };
  handleRedioMarketing = (data, index, dataLength) => {
    let checkValue = this.state.selected.includes(data);
    var selectedValue = checkValue.toString() === "true" ? true : false;
    // console.log(checkValue)
    return (
      // <Radio.Group buttonStyle="solid">
      //   <Radio.Button
      //     value={data}
      //     onChange={this.onSelectChange}
      //     onClick={this.handleClick}
      //     style={{
      //       borderRadius: "50px",
      //       textAlign: "center",
      //       marginLeft: "15px",
      //       backgroundColor: checkValue.toString() == 'true' ? "#1890ff" : "#ffff",
      //       color:checkValue.toString() == 'true' ? "#fff" : "#000",

      //     }}
      //     // checked={selectedValue}
      //   >
      //     {data}
      //   </Radio.Button>
      // </Radio.Group>
      <>
        <div>
          <label>
            <input
              name={data}
              type="checkbox"
              value={data}
              onChange={this.onSelectChange}
              onClick={this.handleClick}
              className="visually-hidden"
              checked={checkValue}
            />
            <div className="btn">{data}</div>
          </label>
        </div>
      </>
    );
  };
  // handlePrevious = (e) => {
  //   e.preventDefault();
  //   this.window.history.back();
  // };
  render() {
    const {
      selectedKeys,
      selecteRedio,
      isdeselect,
      marketing,
      selectedInternet,
    } = this.state;
    // console.log(selectedInternet, "selectedInternet");
    // const Selection = {
    //   selectedKeys,
    //   onChange: this.onSelectChange,
    // };
    const { marketdata, isDeslected } = this.props;
    // const history = useHistory();

    // console.log(this.state.selected, "resdfghjk");
    // console.log(this.props.data);
    return (
      <Row>
        <div  className="progress-mobile">
          <Progress
            percent={this.props.count * 10}
            size="small"
            status="active"
          />
        </div>
        <Col span={24}>
          <div style={{ textAlign: "center", marginTop: "100px" }}>
            <h1 style={{ fontWeight: "bold" }}>
              What type of marketing/advertising services do you offer?
            </h1>
          </div>
        </Col>

        <Col span={24}>
          <Form.Item className="Formitem">
            <p style={{ color: "gray", textAlign: "center" }}>
              select 1 or more
            </p>
          </Form.Item>
        </Col>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Col lg={12} md={12} sm={24} xs={24} className="service-area">
            <Form.Item className="Formitem">
              {marketing.map((data, index) => (
                <>
                  <div
                    style={{
                      width: "max-content",
                      float: "left",
                      marginLeft: "10px",
                    }}
                  >
                    {this.handleRedioMarketing(data, index, marketing.length)}
                  </div>

                  {index === marketing.length - 1 && (
                    <div
                      style={{
                        width: "max-content",
                        float: "left",
                        marginLeft: "10px",
                      }}
                    >
                      <div>
                        {/* <Link to="/app/Internetmarketing">       */}
                        <label>
                          <input
                            name={"Internet marketing"}
                            type="checkbox"
                            value={selectedInternet}
                            // onChange={index === marketing.length - 1&& this.onSelectChangeInternet}
                            // onClick={index === marketing.length - 1&&this.handleClickInternet}
                            // onChange={this.onSelectChange}
                            onClick={() =>
                              this.handleInternetMarketing("Internet marketing")
                            }
                            className="visually-hidden"
                            checked={selectedInternet === "Internet marketing"}
                          />
                          <div className="btn">Internet marketing</div>
                        </label>
                        {/* </Link> */}
                      </div>
                    </div>
                  )}
                </>
              ))}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="1"
                type="primary"
                loading={this.state.loading}
                // onClick={ this. window.history.back()}
                onClick={() =>
                  this.props.handlePrevious(
                    "/app/Area",
                    "doneRegistration",
                    this.props.data
                  )
                }
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                  marginBottom: "80px",
                }}
              >
                Previous
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              {/* <Link to="/app/Platform"> */}
              <Button
                key="1"
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  // marginLeft: "4%",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Next
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Marketing;
