import React, { Component } from "react";
import { Row, Col, Divider, Card } from "antd";
import image3 from "../../Images/image3.jpg";

export default class About extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col span={20} offset={2}>
            <Divider>
              <h1>About Us</h1>
            </Divider>
          </Col>
        </Row>

        <Row>
          <Col
            xl={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <Card
              bordered={false}
              bodyStyle={{ padding: "25px" }}
              style={{ backgroundColor: "#F0F2F5" }}
              //   extra={
              //     loginUserProfile &&
              //     loginUserProfile?.userProfile === "A" &&
              //     edit_aboutUs_page
              //   }
              className="padding-card1-about"
            >
              <Row>
                <Col
                  xl={{ span: 10, offset: 1 }}
                  lg={{ span: 10, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <img
                    alt="business"
                    src={image3}
                    className="img-about"
                    style={{
                      height: "200%",
                      width: "100%",
                      marginTop: "10vw",
                    }}
                  />
                </Col>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <h2 className="para-about">
                    <p>
                      {" "}
                      Having amassed over 20 years of experience in media sales
                      and marketing, our team conceived the groundbreaking idea
                      behind MediaPiq. Throughout our journey, we personally
                      witnessed the daunting challenges that small businesses
                      and non-profit organizations encounter when striving to
                      connect with their target audiences.
                    </p>{" "}
                    <p>
                      Today, the challenge has intensified. With an overwhelming
                      abundance of traditional and digital platforms, selecting
                      the right one can be an overwhelming task. Few businesses
                      can afford to gamble their marketing budgets on trial and
                      error. Enter MediaPiq. We employ a cutting-edge algorithm
                      that harmonizes your business's needs and resources with
                      our comprehensive database of media outlets and platforms.
                      The outcome? Meticulously curated matches that present the
                      optimal opportunity to successfully engage the very people
                      you desire, while avoiding wasteful expenditures on
                      unsuitable media channels.
                    </p>
                    <p>
                      {" "}
                      Reach out to MediaPiq today to delve deeper into how we
                      can empower you to connect with potential customers,
                      fostering your business's growth and prosperity.
                    </p>
                    <p>
                      {" "}
                      Contact MediaPiq today to learn more about how we can help
                      you reach potential customers and build your business.
                    </p>
                  </h2>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
