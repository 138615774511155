import React, { Component } from "react";
import { Row, Col, Card, Button } from "antd";
import image1 from "../../Images/image1.jpg";
import { getArticle } from "../../Api/Apis";
import AppConfig from "../config/AppConfig";

export default class Media extends Component {
  constructor() {
    super();
    this.state = {
      articles: [],
    };
  }

  componentDidMount = async () => {
    try {
      getArticle(AppConfig["entityNames"]["parseXml"]).then((result) => {
        // console.log("result", result.data);
        if(result.success){
        this.setState({articles:result.data});
        }
      });
    } catch (error) {
      console.log("error", error);
    } 
  };

  render() {
    return (
      <div>
        <div className="main-container">
          <div className="firstDiv">
            <div className="imageDiv">
              <img src={image1} alt="imge" className="img-view" />
            </div>
            <div>
              <Card className="card-view">
                <div>
                  {" "}
                  <h2 className="heading-copy">
                    {" "}
                    <strong>
                      Does your business specialize in offering marketing
                      services?
                    </strong>{" "}
                  </h2>
                </div>
                <p className="para2-market">
                  {" "}
                  Got a great marketing platform or expertise to help businesses
                  with promotions? MediaPiq can link you up with businesses
                  seeking your services. We take care of finding potential
                  clients. Simply set up a free business profile on MediaPiq,
                  sharing information about your platform like your area of
                  expertise, marketing services, audience, and pricing. When our
                  system finds a match, you'll get an email with instructions on
                  how to connect. Simple as that!
                </p>
              </Card>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "2vw", padding: "20px" }}>
          <Row
            justify="space-between"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Col
              xl={{ span: 11, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              md={{ span: 20, offset: 1 }}
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 12, offset: 0 }}
            >
              <b>
                <h2 className="head-view">LATEST ARTICLES </h2>{" "}
              </b>
            </Col>
            <Col
              xs={{ span: 4, offset: 7 }}
              xl={{ span: 4, offset: 8 }}
              sm={{ span: 4, offset: 7 }}
              md={{ span: 3, offset: 0 }}
            >
              <Button type="primary">View all</Button>
            </Col>
          </Row>
          <Row
            style={{ marginTop: "2vw" }}
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
              xxl: 16,
            }}
          >
            <Col
              xxl={{ span: 22, offset: 2 }}
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
            >
              {this.state.articles.map((article, index) => (
                <Col
                  className="gutter-row"
                  xs={{span:22,offset:2}}
                  sm={{span:21,offset:2}}
                  md={{span:21,offset:1}}
                  lg={{span:12,offset:0}}
                  xl={{span:12,offset:0}}
                  xxl={7}
                  style={{ marginBottom: "20px" }}
                >
                  <div className="cardStyle">
                    <Card className="card case-study-card">
                      <a href={article.link} target="_blank" rel="noreferrer">
                        <img
                          src={article.image}
                          alt="Article"
                          className="article"                         
                        />
                        <br />
                        <br />
                        <h2 className="title-view">{article.title}</h2>
                        {/* <p className="desc-view">{(article.description)}</p> */}
                        <Button type="primary">Read More</Button>
                      </a>
                    </Card>
                  </div>
                </Col>
              ))}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
